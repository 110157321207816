.process {
    display: grid;
    padding: 6rem 10vw 0;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(5, 1fr);
    grid-auto-rows: 50px;
}
.process > div {
    text-align: left;
    margin-bottom: 2rem;
}
.process > div > h1 {
    font-size: 5rem;
    font-weight: 500;
}
.process > div > p {
    margin-top: 2rem;
    padding: 1rem;
    /* background: #fff; */
    /* border: 4px solid var(--blue); */
    border-radius: 8px;
}
.process-img, .process-main {
    grid-row: span 1;
    grid-column: span 1;
}
.full-row {
    grid-column: span 2;
}
.process-img {
    padding-top: 4rem;
    margin-top: 2rem;
    margin-right: 4rem;
    border-top: 6px solid var(--dark);
    border-left: 6px solid var(--dark);
    border-radius: 100px 0 0 0;
    position: relative;
}
.process-img:after {
    content: "";
    position: absolute;
    bottom: -40px;
    left: -23px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 6px solid var(--blue);
    background: var(--blue);
}
.process-img:before {
    content: "";
    position: absolute;
    top: -28px;
    right: -50px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 6px solid var(--dark);
    background: var(--dark);
}
.right-img {
    border-right: 6px solid var(--dark);
    border-left: 0;
    border-radius: 0 100px 0 0;
    margin-left: 4rem;
    margin-right: 0;
}
.right-img:after {
    content: "";
    position: absolute;
    bottom: -40px;
    right: -23px;
    left: unset;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 6px solid var(--blue);
    background: var(--blue);
}
.right-img:before {
    content: "";
    position: absolute;
    left: -50px;
    top: -28px;
    right: unset;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 6px solid var(--dark);
    background: var(--dark);
}
.process-img, .right > h1 {
    text-align: right;
}
.process-img > img {
    width: 300px;
    transform: rotate(-40deg);
    display: none;
}
.process-img .left-arrow {
    transform: rotate(40deg) scaleX(-1) ;
}
.service-img > img {
    width: 800px;
}
