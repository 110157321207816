.home-box {
    height: 400px;
    position: relative;
    margin: 20px 0;
}
.home-box:last-child {
    margin-bottom: 0;
    margin-bottom: 0;
}
.home-box h1 {
    display: block;
    margin: 0 0 20px;
    font-size: 2.5rem;
}
.home-box > h2 {
    font-size: 1.2rem;
    padding-top: 1rem;
}
.flex-box {
    height: auto;
    display: flex;
}
.home-box > div {
    flex: 1;
    overflow: hidden;
}
.home-box > div > img {
    width: 100%;
    box-shadow: var(--shadow);
    transition: 0.4s;
}
.home-box:hover > div > img {
    transition: 0.4s;
    transform: scale(1.04,1.04);
}
.home-box > .img-l {
    border-radius: 0 32px 32px 0;
}
.home-box > .img-r {
    border-radius: 32px 0 0 32px;
}
.home-box > div > h1 {
    font-size: 4rem;
    margin: 0;
    font-weight: 500;
}
.home-box > div > p {
    font-size: 1.2rem;
}
.home-box > div > h2 {
    margin: 0;
}
.home-box > div > a,.home-box > div > button {
    float: right;
}
.home-wrap {
    display: inline-block;
    color: var(--dark);
    place-self: center;
    text-align: right;
    /* padding: 0 40px; */
    padding: 0 160px 0 40px;
}
.h-wrap-l {
    text-align: left;
    padding: 0 40px 0 160px;
}
.home-box > .h-wrap-l > a,.home-box > .h-wrap-l > button {
    float: left;
}
.clients-box {
    padding: 0 0 80px;
    height: auto;
    background: url(../../assets/img/banner/img-1.jpg);
}
.clients-box > div,.work-box > div {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
}
.work-box {
    padding: 0 0 80px;
    height: auto;
    background: url(../../assets/img/banner/img-3.jpg);
    color: #fff;
}
.clients-wrap > div {
    width: 250px;
    height: 250px;
    border-radius: 32px;
    margin: 0 0.6rem;
    float: left;
    position: relative;
}
.clients-wrap > div:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 90%;
    height: 90%;
    margin: auto;
    background: rgba(255,255,255,0.4);
    z-index: 100;
    border-radius: 22px;
    border: 1px solid rgba(255,255,255,0.6);
}
.clients-wrap > div > svg {
    font-size: 5rem;
    color: rgba(255,255,255,1);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}
.clients-wrap > div h1 {
    font-size: 1.5rem;
    position: absolute;
    bottom: -2.2rem;
    left: 0;
    right: 0;
    margin: auto;
} 
.get-quote {
    padding: 0 10vw 1rem;
}
.get-quote > div {
    padding: 1rem 0;
}
.get-quote > div > h1 {
    font-size: 3rem;
    font-weight: 600;
}
.get-quote > div > a {
    display: inline-block;
    width: 300px;
    text-align: center;
    padding: 16px 0;
    margin: 1rem auto;
    border-radius: 4px;
    font-size: 1.2rem;
    background: none;
    transition: 0.2s;
}
.get-quote > div > .btn-primary {
    color: #fff;
    border: 2px solid var(--main);
    background: var(--main);
}
.get-quote > div > .btn-primary:hover {
    color: var(--main);
    background: #fff;
    transition: 0.2s;
}
.get-quote > div > .btn-grey {
    color: var(--grey);
    border: 2px solid var(--grey);
}
.get-quote > div > .btn-grey:hover {
    color: #fff;
    background: var(--grey);
    transition: 0.2s;
}