.banner {
    position: relative;
    height: 600px;
    overflow: hidden;
}
.banner-parallax {
    padding: 0 0 80px;
    height: 500px;
    background: url(../../assets/img/banner/img-9.jpg);
}
.banner > img {
    width: 100%;
}
.banner .cta {
    position: absolute;
    top: -270px;
    left: 0;
    right: 0;
    width: fit-content;
    height: fit-content;
    text-align: left;
    margin: auto;
    bottom: 0;
    color: #fff;
    padding: 0.6rem 2rem;
}
.banner .cta > h1 {
    font-weight: 200;
    font-size: 3.2rem;
    color: var(--dark);
}
.banner .cta > h1 > span {
    color: var(--blue);
    font-weight: 300;
}
.banner .cta > p {
    font-size: 1.1rem;
    color: rgba(0,0,0,0.6);
}
.banner .cta > .btn-cta {
    width: 300px;
    text-align: center;
    padding: 16px 0;
    background: var(--main);
    margin: 1rem 0; 
    display: block;
    border-radius: 4px;
    color: #fff;
}
.banner .cta > .btn-cta svg {
    margin-left: 0.4rem;
}