.about {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 230px;
}
.about > div {
    text-align: left;
}
.about > div > h1 {
    font-size: 5rem;
    font-weight: 500;
}
.about > div > h1 > span {
    font-size: 1.5rem;
    font-weight: 500;
    color: #afafaf;
}
.about > div > p {
    margin-top: 2rem;
}
.about-img, .about-main {
    grid-row: span 1;
    grid-column: span 1;
    padding: 6rem 1rem 4rem;
}
.about-main {
    padding-left: 10vw;
}
.about-img {
    padding-right: 10vw
}
.about-img > img {
    width: 100%;
    border-radius: 4px;
}
.about-stats {
    grid-column: span 2;
    display: flex;
    padding: 2rem 0;   
    background: #fff; 
}
.about-stats > div {
    flex: 1;
    color: var(--dark);
    text-align: center;
    padding: 0 2rem;
}
.about-stats > div > h2 {
    font-size: 6rem;
    font-weight: 500;
}
.about-stats > div > h2 > span {
    font-family: auto;
}
.about-stats > div > p {
    font-size: 1rem;
}
.about-peeps {
    width: 100%;
    background: var(--light);
    padding: 4rem 10vw 4rem;
}
.about-peeps > h2 {
    font-size: 2rem;
    font-weight: 500;
}
.about-peeps .peeps-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    height: max-content;
}
.about-peeps .peeps-wrap:hover>:not(:hover) {
    opacity:0.4
}
.about-peeps .peeps-wrap .peep {
    grid-column: span 1;
    grid-row: span 1;
    height: fit-content;
    margin: 2rem 0;
    transition: 0.2s;
    position: relative;
}
.about-peeps .peep > img {
    height: 14rem;
    display: block;
    margin: 0.4rem auto;
    position: relative;
    z-index: 1;
}
.about-peeps .peep:hover > img {
    margin-top: -0.5rem;
    margin-bottom: 1rem;
}
.about-peeps .peep > span {
    position: absolute;
    top: -60px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: var(--orange);
    margin: auto;
    z-index: 0;
}
.about-peeps .peep > h3 {
    font-weight: 500;
    font-size: 1.4rem;
    position: relative;
    z-index: 1;
}
.about-peeps .peep > h4 {
    font-size: 1rem;
    color: #afafaf;
    position: relative;
    z-index: 1;
}