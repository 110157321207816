.menu-container {
    display: flex;
    /* padding: 4px 80px; */
    /* background: #fff; */
}
.menu-container > a {
    flex: 1;
    color: var(--dark);
    font-size: 1rem;
    font-weight: 300;
    /* transition: 0.2s; */
    position: relative;
    white-space: nowrap;
}
.menu-container > a::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0;
    height: 1px;
    margin: auto;
    background: var(--dark);
}
.menu-container > a > svg {
    margin: 0 8px;
}
.menu-container > a:hover::after {
    /* color: var(--main); */
    transition: 0.2s;
    width: 100%;
}
.dropdown {
    position: absolute;
    inset: 0px auto auto 0px;
    transform: translate(-4px, 22px);
    background: #fff;
    width: max-content;
    padding: 24px 20px;
    border-radius: 4px;
    overflow: hidden;
    /* opacity: 0; */
    display: none;
    transition: 0.2s;
    border: 2px solid var(--dark);
}
.menu-container > a:hover > .dropdown {
    /* opacity: 1; */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    transition: 0.2s;
}
.dropdown a,.dropdown p {
    display: block;
    padding: 15px 20px;
    text-align: left;
    transition: 0.2s;
    font-weight: 500;
    position: relative;
    grid-column: span 1;
    width: 250px;
    border-radius: 8px;
}
.dropdown a:hover {
    transition: 0.2s;
    color: var(--blue);
    background: var(--light);
}
.dropdown p:hover {
    transition: 0.2s;
    color: var(--blue);
}
.dropdown a::after,.dropdown p::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 30px;
    /* margin: auto; */
    height: 1px;
    width: 0;
    background: var(--dark);
    transition: 0.2s;
}
.dropdown a:hover::after,.dropdown p:hover::after {
    width: 85%;
    left: 30px;
    transition: 0.2s;
}
.dropdown p > span {
    color: #999;
    display: block;
    font-size: 0.8rem;
    white-space: pre-wrap;
    font-weight: 300;
}
.tech-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: -2rem;
}
.tech-wrap .tech {
    /* flex: 1 1; */
    padding: 2rem;
    align-self: center;
    position: relative;
    overflow: hidden;
    transition: 0.2s;
}
.tech-wrap .tech .tech-logo {
    height: 60px;
}
.tech-wrap .tech .PHP,.tech-wrap .tech .jQuery {
    height: auto;
    width: 60px;
}
.tech-wrap .tech > span {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 100%;
    margin: auto;
    height: fit-content;
    width: 100%;
    text-align: center;
    background: #fff;
    border-radius: 50px;
    transition: 0.2s;
    opacity: 0;
    box-shadow: var(--shadow);
}
.tech-wrap .tech:hover > span {
    opacity: 1;
    top: 0;
    transition: 0.2s;
}