.mobilemenu {
    z-index: 100;
    overflow: hidden;  
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0%;
    background: var(--dark);  
}
.mm-true {
    height: 100%;
    transition: 0.2s;
}
.mm-false {
    height: 0;
    transition: 0.2s;
}
.mobilemenu > div {
    /* position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--dark); */
}
.mm-head {
    min-height: 65px;
}
.mm-head > img {
    width: 60%;
    margin: 1.5rem auto 0.5rem;
    /* float: right; */
}
.mm-head > svg {
    float: right;
    margin: 0.8rem;
    font-size: 1.6rem;
    color: #fff;
}
.mm-menu {
    padding: 4rem 2rem;
    align-items: center;
}
.mm-menu a {
    text-align: left;
    padding: 0.4rem;
    transition: 0.2s;
    display: block;
    color: #fff;
    font-size: 1.6rem;
}
.mm-menu a > svg {
    margin-right: 0.6rem;
    color: #a9a9a9
}
.mm-menu a:hover {
    background: #f4f4f4 ;
    transition: 0.2s;
    color: #3d3d3d
}
.mm-menu a:hover > svg {
    color: var(--main)
}
.mm-sign {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: fit-content;
}
.mm-sign > button:first-child {
    float: left;
    background: none;
    font-size: 0.9rem;
    min-width: 50px;
    margin: 0.4rem;
    border-radius: 0.2rem;
    padding: 0.3rem 0.6rem;
    cursor: pointer;
    text-decoration: none;
}
.mm-sign > button > svg {
    font-size: 1.4rem;
    margin: 0 0.4rem 0;
}
.mm-sign > .btn-2 {
    float: right;
}
.close-mm {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
}
.close-mm > svg {
    color: #fff;
    font-size: 2rem;
}
.mm-socials a {
    color: #fff;
    margin-right: 2rem;
    font-size: 2.5rem;
    text-align: center;
}
.mm-socials a:last-child {
    margin-right: 0;
}