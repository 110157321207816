.quote + .get-quote {
    display: none;
}
.pill-holder {
    position: relative;
    padding-top: 3.4rem;
    overflow: hidden;
}
.pill-holder .pill {
    background: none;
    display: inline-block;
    width: 100%;
    width: fit-content;
    margin-top: 0.4rem;
    margin-left: 0.6rem;
}
.pill-holder .pill > label {
    font-size: 1rem !important;
    color: var(--dark);
    padding: 0.4rem 1rem;  
    border-radius: 50px;
    border: 1px solid #ced4da;
}
.pill-holder .pill .btn-pill-check:hover {
    border-color: var(--blmainue);
}
.pill-holder .pill:hover > label {
    color: var(--main);
}
.pill-holder .pill .btn-check:checked + .btn-pill-check {
    background: var(--main);
    color: #fff;
    border-color: var(--main);
}
.quote-form div input, .quote-form div textarea {
    height: auto;
}