.head {
    background:rgba(255,255,255,0.6);
    height: 100px;
    padding: 0px 80px;
    display: flex;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 100;
    border-bottom: 2px solid rgba(255,255,255,0.4);
    transition: 0.4s;
}
.head:hover {
    background: rgba(255,255,255,1);
    box-shadow: var(--shadow);
    transition: 0.4s;
}
.head > div:first-child > svg {
    position: relative;
    z-index: 1000;
}
.head > div {
    flex: 1.5 1;
    align-self: center;
    height: 100%;
    align-items: center;
}
.head .logo {
    /* flex: 2; */
    cursor: pointer;
    display: flex;
}
.head .logo > img {
    height: 90%;
    vertical-align: middle;
    float: left;
}
.head .search {
    flex: 1;
    /* display: grid; */
    display: none;
}
.head .search svg {
    float: right;
}
.head .user .display-name {
    font-size: 1.2rem;
    width: 40px;
    height: 40px;
    /* background: linear-gradient( red, var(--main)); */
    background: var(--highlight);
    box-shadow: var(--shadow);
    color: #fff;
    border-radius: 50%;
    float: right;
    margin-right: 1rem;
    border: 2px solid rgba(0,0,0,0.1);
    line-height: 2.2rem;
}
.menu-container > a {
    flex: 1 1;
    color: var(--dark);
    font-size: 0.9rem;
    /* transition: 0.2s; */
}
.head .socials {
    flex: 1;
    display: grid;
}
.head .socials > div {
    text-align: right;
}
.head .socials svg {
    text-align: right;
    font-size: 2rem;
    color: #aaa;
    margin: 0 0.8rem;
    cursor: pointer;
}
.head .socials svg:hover {
    color: var(--blue)
}
.head .mm {
    display: none;
}
.mm-quote {
    margin-top: 4rem;
}
.mm-quote .btn-pill {
    font-size: 1.5rem;
    padding: 1rem 2rem;
}