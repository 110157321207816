/* MOBILE - LANDSCAPE */
@media only screen and (max-width: 920px) {
    .head {
        height: 80px !important;
        padding: 0 20px !important;
    }
    .head .mm {
        display: grid !important;
        float: right;
    }
    .head .mm > div {
        text-align: right;
        
    }
    .head .mm > div svg {
        font-size: 2rem;
    }
    .head .search, .head .socials, .head .menu-container {
        display: none !important;
    }
    .flex-box {
        flex-direction: column;
    }
    .mm-head > img {
        width: 25%;
    }
    .mm-menu {
        padding: 1rem 2rem;
    }
    .mm-menu a {
        font-size: 1.2rem;
    }
    .foot {
        display: flex !important;
        flex-direction: column;
        padding: 0 20px !important
    }
    .foot > div {
        flex: 1;
        padding: 0 !important;
    }
    .foot > .foot-hl {
        text-align: center !important;
    }
    .foot > div img {
        height: 150px !important;
    }
    .foot > .newsletter h2, .foot > p {
        margin-top: 15px !important;
    }
    .banner {
        height: 450px;
    }
    .banner .cta {
        top: 0;
    }
    .banner .cta > .btn-cta {
        margin: 12rem 0 1rem;
    }
    .banner .cta > h1 {
        font-size: 2.5rem;
    }
    .service-img > img {
        width: 80%;
    }
    .process {
        display: block;
    }
    .process > div {
        display: block;
    }
    .process > .process-img {
        display: none;
    }
    .process > div > h1 {
        font-size: 2rem;
        position: relative;
        text-align: left;
        width: fit-content;
    }
    .process > div > h1::after {
        content: "";
        position: absolute;
        bottom: -10px;
        left: 0;
        height: 4px;
        width: 100%;
        background: var(--dark);
        border-radius: 50px;
    }
    .process > div:first-child > h1 {
        font-size: 4rem;
    }
    .process > div:first-child > h1::after {
        display: none;
    }
    .privacy-policy {
        flex-direction: column-reverse;
    }
    .policy-img > img {
        width: 100%;
        margin-top: -40px;
    }
    .scroll-to-top {
        bottom: 4.5rem;
    }
}

/* MOBILE - PORTRAIT */
@media only screen and (max-width: 650px) {
    .head {
        height: 80px !important;
        padding: 0 20px !important;
    }
    .head .mm {
        display: grid !important;
        float: right;
    }
    .head .mm > div {
        text-align: right;
        
    }
    .mm-head > img {
        width: 60%;
    }
    .head .mm > div svg {
        font-size: 2rem;
    }
    .head .search, .head .socials, .head .menu-container {
        display: none !important;
    }
    .flex-box {
        flex-direction: column;
    }
    .h-wrap-l, .home-wrap {
        padding-right: 20px !important;
        padding-left: 20px !important;
    }
    .clients-box > div, .work-box > div {
        display: block;
        padding: 0;
        white-space: nowrap;
    }
    .clients-wrap > div {
        display: block;
        margin: 3rem auto;
        float: none;
        width: 200px;
        height: 200px;
    }
    .banner > img {
        height: 460px;
        width: auto !important;
        margin-left: -196px;
        display: block;
    }
    .banner .cta {
        left: 0 !important;
        top: 2rem !important;
    }
    .banner .cta > h1 {
        font-size: 2rem;
    }
    .banner .cta > p {
        color: rgba(255,255,255,0.8);
        margin-top: 10rem;
    }
    .banner .cta > .btn-cta {
        margin: 1rem 0;
    }
    .foot {
        display: flex !important;
        flex-direction: column;
        padding: 0 20px !important
    }
    .foot > div {
        flex: 1;
        padding: 0 !important;
    }
    .foot > .foot-hl {
        text-align: center !important;
    }
    .foot > div img {
        height: 150px !important;
    }
    .foot > .newsletter h2, .foot > p {
        margin-top: 15px !important;
    }
    .contact {
        flex-direction: column;
        padding-top: 4rem !important;
    }
    .contact > div > h1 {
        font-size: 2.4rem !important;
    }
    .contact-form > div label {
        font-size: 1.1rem !important;
    }
    .contact-form > div input, .contact-form > div textarea {
        font-size: 1.2rem !important;
    }
    .contact-form div label,.quote-form div label {
        font-size: 1rem;
    }
    .tech-wrap {
        padding: 0 0.4rem;
    }
    .tech-wrap .tech {
        padding: 1rem;
    }
    .tech-wrap .tech .tech-logo {
        height: 50px;
    }
    .get-quote > div > .btn-primary {
        margin-right: 0;
    }
    .msg-success {
        width: 90%;
        text-align: center;
    }
    
    .about {
        display: block;
    }
    .about > div > p {
        /* font-size: 1.4rem; */
    }
    .about .about-stats {
        flex-direction: column;
    }
    .about .about-stats > div {
        margin: 1rem 0;
    }
    .about-stats > div > h2 {
        font-size: 4rem;
    }
    .about-peeps {
        padding: 4rem 2vw 4rem;
    }
    .about-peeps .peeps-wrap {
        grid-template-columns: 1fr 1fr;
    }
    .about-main {
        padding-top: 2rem;
    }
    .about-img {
        display: none;
    }
    .about > div > h1 > span {
        display: block;
    }
    .get-quote {
        padding: 0 4vw 1rem;
    }
    .services {
        flex-direction: column;
    }
    .services > div {
        width: 90%;
        margin: 1rem auto;
    }
    .services > div > img {
        width: 100%;
    }
    .services-container > h1 {
        margin-left: 1rem;
    }
    .pill-holder > .pill {
        margin-left: 0.4rem;
    }
    .privacy-policy > div > h1 {
        font-size: 3.5rem;
    }
    .policy-body p {
        font-size: 1rem;
    }
    .scroll-to-top > span {
        display: none;
    }
    .scroll-to-top > svg {
        margin-right: 0;
    }
    
}
@media only screen and (min-width: 1518px) {
    .about > div > h1 > span {
        display: block;
    }
}
@media only screen and (min-width: 1920px) {
    .banner > img {
        margin-top: -120px;
    }
}
/* 
WIDESCREEN >= 1200px
DESKTOP >= 992px
LAPTOP >= 768px
TABLET >= 576px
MOBILE < 576px
*/