.services-container > h1 {
    font-size: 2.5rem;
    font-weight: 600;
    text-align: left;
    margin-left: 5rem;
    margin-top: 10vh;
}
.services {
    display: flex;
    flex-wrap: wrap;
    margin: 1rem auto;
    place-content: center;
}
.services > div {
    flex: 1;
    display: block;
    background: #fff;
    border-radius: 4px;
    padding: 1rem;
    margin: 1rem;
    width: 30%;
    text-align: left;
    /* height: 300px; */
}
.services > div > h2 {
    font-weight: 600;
    font-size: 1.5rem;
    margin: 2rem 0;
    position: relative;
    width: fit-content;
}
.services > div > h2:after {
    content: "";
    height: 2px;
    width: 0;
    background-color: var(--dark);
    position: absolute;
    left: 0;
    bottom: -10px;
    transition: 0.2s;
}
.services > div > span {
    color: var(--grey);
    font-weight: 300;
    margin-bottom: 1rem;
    display: block;
}
.services > div > img {
    margin: 0 auto;
    display: block;
    height: 250px;
    border-radius: 4px;
}
.services > div:hover > h2:after {
    transition: 0.2s;
    width: 100%;
}
