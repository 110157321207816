.privacy-policy {}
.privacy-policy {
    display: flex;
    padding: 6rem 10vw 4rem;
    position: relative;
}
.privacy-policy > div {
    flex: 1;
    text-align: left;
}
.privacy-policy > div > h1 {
    font-size: 5rem;
    font-weight: 500;
}
.policy-img > img {
    width: 85%;
    float: right;
    margin-top: -80px;
}
.policy-body {
    text-align: left;
    padding: 2rem 10vw;
}
.policy-body h2 {
    font-size: 2rem;
    font-weight: 500;
    padding: 1rem 0;
    position: relative;
    width: fit-content;
}
.policy-body h2::after {
    content: "";
    position: absolute;
    bottom: 0.5rem;
    left: 0;
    height: 2px;
    width: 100%;
    background: var(--dark);
}
.policy-body h2 > span {
    margin-right: 2rem;
}
.policy-body h3 {
    color: var(--grey)
}
.policy-body p {
    margin: 1.5rem 0;
    font-size: 1.2rem;
}
.policy-body p > span {
    font-weight: 500;
}
.policy-body p > a, .policy-body li > a {
    color: var(--blue);
    margin: 0 4px;
}
.policy-body ol li {
    padding: 0.5rem 1rem;
    transition: 0.2s;
    cursor: pointer;
    font-weight: 400;
}
.policy-body ol li:hover {
    color: var(--main);
    transition: 0.2s;
}
.policy-body .click-here {
    color: var(--blue);
    cursor: pointer;
}