/* FONT */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

/*COLOURS*/
:root {
  --dark: #010101;
  --dark-alt: #26324E;
  --light: #F5F7FB;
  --light-alt: #FFFFF7;
  --grey: #afafaf;
  --red: #f55456;
  --green: #25d366;
  --orange: #ffbe76;
  --yellow: #ffe234;
  --blue: #00c1d5;
  --main: #5928e5;
  --secondary: #eac3de;
  --highlight: #ffcbcb;
  --shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --shadow-alt: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem,
    rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
  --shadow-big: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}
body {
  font-family: Poppins, sans-serif;
  margin: 0;
  font-size: 1em;
  background: var(--light);
}
.dark {
  background: var(--dark);
  color: var(--light);
}
.dark input {
  color: var(--light);
}
.light {
  background: var(--light);
  color: var(--dark);
}
.light input {
  color: var(--dark);
}
.goo {
  cursor: pointer;
}
.goo-l {
  position: absolute;
  top: 0.8rem;
  left: -0.4rem;
  width: 24px;
  background-color: var(--highlight-color);
  border-radius: 0 25px 25px 0 !important;
  cursor: pointer;
  transition: 0.2s;
}
.goo-r {
  position: absolute;
  top: 1.6rem;
  right: 0;
  width: 33px;
  background-color: var(--highlight-color);
  border-radius: 25px 0 0 25px;
  height: 30px;
  z-index: 15;
  transition: 0.2s;
}
.goo > svg {
  z-index: 200;
  position: relative;
  color: #fff;
  margin-left: 0.4rem;
  transition: 0.2s;
}
.goo-r > svg {
  vertical-align: middle;
}
.goo-l:hover {
  width: 35px;
  transition: 0.2s;
  box-shadow: var(--shadow);
}
.goo-l:hover > svg {
  margin-left: 0.6rem;
  transition: 0.2s;
}
.goo-r:hover {
  width: 40px;
  transition: 0.2s;
  box-shadow: var(--shadow);
}
.goo-l:before {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: -50px;
  left: 0;
  height: 50px;
  width: 15px;
  border-top-left-radius: 25px;
  box-shadow: 0 -22px 0 0 var(--highlight-color);
}
.goo-l:after {
  content: "";
  position: absolute;
  background-color: transparent;
  top: -50px;
  left: 0;
  height: 50px;
  width: 15px;
  border-bottom-left-radius: 25px;
  box-shadow: 0 22px 0 0 var(--highlight-color);
}
.goo-r:before {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: -47px;
  right: 0;
  height: 50px;
  width: 25px;
  border-top-right-radius: 25px;
  box-shadow: 0 -25px 0 0 var(--highlight-color);
}
.goo-r:after {
  content: "";
  position: absolute;
  background-color: transparent;
  top: -48px;
  right: 0;
  height: 50px;
  width: 25px;
  border-bottom-right-radius: 25px;
  box-shadow: 0 25px 0 0 var(--highlight-color);
}
.btn {
  background: var(--red);
  color: #fff;
  font-size: 1rem;
  min-width: 50px;
  margin: 0.4rem;
  border-radius: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 3px solid rgba(0, 0, 0, 0.4);
  cursor: pointer;
  text-decoration: none;
}
.btn:hover {
}
.btn:active {
  border-bottom-color: rgba(0, 0, 0, 0);
}
.btn-2 {
  background: var(--main);
  color: #fff;
  font-size: 0.9rem;
  min-width: 50px;
  margin: 0.4rem;
  border-radius: 0.2rem;
  padding: 0.3rem 0.6rem;
  cursor: pointer;
  text-decoration: none;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: none;
}
.btn-simp {
  color: #333;
  font-size: 0.9rem;
  min-width: 50px;
  margin: 0.4rem;
  padding: 0.3rem 0.6rem;
  cursor: pointer;
  text-decoration: none;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: none;
  position: relative;
}

.center-it  {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: fit-content;
  height: fit-content;
}
.shadow {
  box-shadow: var(--shadow);
}
.btn-pill {
  border-radius: 50px;
  border: 2px solid var(--main);
  background: var(--main);
  color: var(--light);
  font-size: 0.9rem;
  min-width: 50px;
  margin: 0.4rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  text-decoration: none;
}
.btn-pill:hover {
  border-color: var(--main);
  background: rgba(0, 0, 0, 0);
  color: var(--main)
}
.btn-simp {
  width: 300px;
  text-align: center;
  padding: 16px 0;
  color: var(--main);
  border: 2px solid var(--main);
  margin: 1rem auto;
  display: block;
  border-radius: 4px;
  font-size: 1.2rem;
  background: none;
}
.btn-simp-active {
  width: 300px;
  text-align: center;
  padding: 16px 0;
  background: var(--main);
  border: 2px solid var(--main);
  margin: 1rem auto;
  display: block;
  border-radius: 4px;
  font-size: 1.2rem;
  color: #fff;
}
.btn-simp svg {
    margin-left: 0.6rem;
}
.btn-simp::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -1px;
  top: 0;
  height: 101%;
  width: 30px;
  background: var(--main);
  transition: 0.2s;
  z-index: -1;
  margin: auto;
  border-radius: 0 50px 50px 0;
}
.btn-simp:hover {
  color: #fff;
}
.btn-simp:hover::after {
  width: 101%;
  border-radius: 0;
  transition: 0.2s;
}
.glass {
  background: linear-gradient(135deg, rgba(255,255,255,0.1), rgba(255,255,255,0));
  backdrop-filter: blur(20px);
  box-shadow: 0 8px 32px 0 rgba(255,255,255,0.18);
  border: 1px solid rgba(255,255,255,0.18);
  border-radius: 32px;
}
.parallax {
  background-attachment: fixed !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  position: relative !important;
}
.input-group {
  display: grid;
  height: 50px;
  grid-template-columns: 65% 35%;
}
.input-group > input {
  width: auto;
  margin: 0;
  grid-column: span 1;
  width: -webkit-fill-available;
  border-radius: 4px 0 0 4px;
}
.input-group > button {
  border-color: rgba(255,255,255,0.1);
  grid-column: span 1;
  width: -webkit-fill-available;
  margin: 0;
  border-radius: 0 4px 4px 0;
}
.scroll-to-top {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 500;
}
.scroll-to-top > svg {
  margin-right: 0.2rem;
}