.foot {
    background: var(--dark);
    /* border-top: 1px solid rgba(255,255,255,0.4); */
    color: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 2fr 1fr;
    padding: 0 80px;
}
.foot > img {
    width: 150px;
    margin: 1rem 0;
}
.foot > p {
    font-size: 1rem;
    color: #bbb;
    padding: 0.6rem 0; 
    grid-column: span 3;
    grid-row: span 1;
}
.foot > div {
    padding: 0.8rem 80px 0;
    grid-column: span 1;
    grid-row: span 2;
}
.foot > .foot-hl {
    text-align: right;
}
.foot > .newsletter {
    text-align: left;
    position: relative;
}
.foot > .newsletter h1 {
    font-size: 4rem;
    margin: 0;
    font-weight: 500;
}
.foot > .newsletter h2 {
    margin: 0 0 -10px;
}
.foot > .newsletter input {
    background: #fff;
    font-size: 1.2rem;
    padding: 0.8rem;
}
.foot > .newsletter > .invalid {
    color: var(--red);
    margin-top: 20px;
}
.foot > div > a {
    font-size: 1.1rem;
    padding: 0.4rem 0;
    display: block;
    color: #e9e9e9
}
.foot > div > a:hover {
    color: var(--highlight)
}
.foot > div img {
    height: 250px;
    display: block;
    margin: 0 auto;
}
.foot .socials svg {
    margin: 1rem;
    font-size: 2rem;
    transition: 0.2s;
}
.foot .socials svg:hover{
    transition: 0.2s;
    color: var(--blue);
}
.success-btn {
    background: var(--green);
}
.success-input::placeholder {
    color: var(--green);
}