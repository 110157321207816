.contact {
	display: flex;
	padding: 6rem 10vw 4rem;
	position: relative;
}
.contact > div {
	flex: 1;
	text-align: left;
}
.contact > div > h1 {
	font-size: 5rem;
	font-weight: 500;
}
.contact > div > p {
	margin-top: 2rem;
}
.contact-form div input,
.contact-form div textarea {
	border: none;
	border-bottom: 1px solid #212529;
	border-radius: 0;
	padding: 1rem 0.75rem;
	height: calc(3.5rem + 2px);
	line-height: 1.25;
	font-size: 1.35rem;
	overflow-y: hidden;
	background: none;
}
.contact-form div input:focus,
.contact-form div textarea:focus {
	color: #212529;
	background-color: #fff;
	border-color: var(--blue);
	outline: 0;
	box-shadow: none;
	background: none;
}
.contact-form div label {
	font-size: 1.35rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
	transform: scale(0.85) translateY(-1rem) translateX(0.15rem) !important;
}
.contact-form p > a {
	color: var(--blue);
	text-decoration: none;
}
.contact-form button {
	float: left;
}
.msg-success {
	color: var(--green);
	padding: 20px 10px;
	box-shadow: var(--shadow);
	border-radius: 8px;
	border: 1px solid #e9e9e9;
	border-left: 8px solid var(--green);
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	height: fit-content;
	width: fit-content;
	background: #fff;
}
.msg-success svg {
	display: inline-block;
	font-size: 1.6rem;
	margin: 0 0.5rem;
}
.msg-success p {
	display: inline-block;
}

.msg-error {
	color: var(--red);
	padding: 20px 10px;
	box-shadow: var(--shadow);
	border-radius: 8px;
	border: 1px solid #e9e9e9;
	border-left: 8px solid var(--red);
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	height: fit-content;
	width: fit-content;
	background: #fff;
}
.msg-error svg {
	display: inline-block;
	font-size: 1.6rem;
	margin: 0 0.5rem;
}
.msg-error p {
	display: inline-block;
}
.slow-hide {
	animation: hideAnimation 0s ease-in-out 5s;
	animation-fill-mode: forwards;
}
@keyframes hideAnimation {
	to {
		visibility: hidden;
		opacity: 0;
		transition: 0.2s;
	}
}